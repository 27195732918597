import React, { useEffect, useState } from "react";
import { db } from "../FB/firebaseConfig.js";
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import './ManageSupportContacts.css'; // Import the CSS

const ManageSupportContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [getInTouchInfo, setGetInTouchInfo] = useState([]);
  const [editModeContact, setEditModeContact] = useState(false);
  const [editModeGetInTouch, setEditModeGetInTouch] = useState(false);
  const [currentContactId, setCurrentContactId] = useState(null);
  const [currentGetInTouchId, setCurrentGetInTouchId] = useState(null);

  useEffect(() => {
    fetchContacts();
    fetchGetInTouchInfo();
  }, []);

  const fetchContacts = async () => {
    const contactsCollection = collection(db, "supportContacts");
    const contactSnapshot = await getDocs(contactsCollection);
    const contactData = contactSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setContacts(contactData);
  };

  const fetchGetInTouchInfo = async () => {
    const getInTouchCollection = collection(db, "getInTouch");
    const getInTouchSnapshot = await getDocs(getInTouchCollection);
    const getInTouchData = getInTouchSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setGetInTouchInfo(getInTouchData);
  };

  const handleAddContact = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "supportContacts"), {
      name: contactName,
      email: contactEmail,
    });
    setContactName("");
    setContactEmail("");
    fetchContacts(); // Refresh contacts list
  };

  const handleGetInTouchSubmit = async (e) => {
    e.preventDefault();
    if (editModeGetInTouch) {
      // Update existing Get in Touch information
      const docRef = doc(db, "getInTouch", currentGetInTouchId);
      await updateDoc(docRef, {
        address: address,
        phone: phone,
        email: contactEmail,
      });
      setEditModeGetInTouch(false);
      setCurrentGetInTouchId(null);
    } else {
      // Add new Get in Touch information
      await addDoc(collection(db, "getInTouch"), {
        address: address,
        phone: phone,
        email: contactEmail,
      });
    }
    setAddress("");
    setPhone("");
    setContactEmail(""); // Clear email field
    fetchGetInTouchInfo(); // Refresh Get in Touch info
  };

  const handleEditContact = (contact) => {
    setEditModeContact(true);
    setCurrentContactId(contact.id);
    setContactName(contact.name);
    setContactEmail(contact.email);
  };

  const handleDeleteContact = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this contact?");
    if (confirmDelete) {
      const docRef = doc(db, "supportContacts", id);
      await deleteDoc(docRef);
      fetchContacts(); // Refresh contacts list
    }
  };

  const handleEditGetInTouch = (info) => {
    setEditModeGetInTouch(true);
    setCurrentGetInTouchId(info.id);
    setAddress(info.address);
    setPhone(info.phone);
    setContactEmail(info.email);
  };

  const handleDeleteGetInTouch = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Get in Touch information?");
    if (confirmDelete) {
      const docRef = doc(db, "getInTouch", id);
      await deleteDoc(docRef);
      fetchGetInTouchInfo(); // Refresh Get in Touch info
    }
  };

  const handleUpdateContact = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "supportContacts", currentContactId);
    await updateDoc(docRef, {
      name: contactName,
      email: contactEmail,
    });
    setEditModeContact(false);
    setCurrentContactId(null);
    setContactName("");
    setContactEmail("");
    fetchContacts(); // Refresh contacts list
  };

  return (
    <div className="manage-support-contacts">
      <h2>Manage Support Contacts</h2>
      <form onSubmit={editModeContact ? handleUpdateContact : handleAddContact}>
        <input
          type="text"
          placeholder="Contact Name"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Contact Email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          required
        />
        <button type="submit">{editModeContact ? "Update Contact" : "Add Contact"}</button>
      </form>
      <h3>Existing Contacts</h3>
      <ul>
        {contacts.map(contact => (
          <li key={contact.id}>
            {contact.name} - {contact.email}
            <button onClick={() => handleEditContact(contact)}>Edit</button>
            <button onClick={() => handleDeleteContact(contact.id)}>Delete</button>
          </li>
        ))}
      </ul>

      <h2>Get in Touch Information</h2>
      <form onSubmit={handleGetInTouchSubmit}>
        <input
          type="text"
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          required
        />
        <button type="submit">{editModeGetInTouch ? "Update" : "Save Get in Touch Info"}</button>
      </form>

      <h3>Current Get in Touch Info:</h3>
      {getInTouchInfo.length > 0 ? (
        <ul>
          {getInTouchInfo.map(info => (
            <li key={info.id}>
              <p>Address: {info.address}</p>
              <p>Phone: {info.phone}</p>
              <p>Email: {info.email}</p>
              <button onClick={() => handleEditGetInTouch(info)}>Edit</button>
              <button onClick={() => handleDeleteGetInTouch(info.id)}>Delete</button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No Get in Touch information available.</p>
      )}
    </div>
  );
};

export default ManageSupportContacts;