import React, { useState, useEffect } from "react";
import { Container, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { useLanguage } from "./LanguageContext";
import { db } from "../../FB/firebaseConfig";  // Import Firestore
import { collection, getDocs, addDoc } from "firebase/firestore";
import "./contactUs.css";

const ContactUs = () => {
  const { language } = useLanguage();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [supportContacts, setSupportContacts] = useState([]);
  const [getInTouch, setGetInTouch] = useState({});

  useEffect(() => {
    const fetchSupportContacts = async () => {
      const querySnapshot = await getDocs(collection(db, "supportContacts"));
      const contacts = querySnapshot.docs.map(doc => doc.data());
      setSupportContacts(contacts);
    };

    const fetchGetInTouch = async () => {
      const querySnapshot = await getDocs(collection(db, "getInTouch"));
      const contactInfo = querySnapshot.docs.map(doc => doc.data());
      setGetInTouch(contactInfo[0] || {}); // Assume there's only one document.
    };

    fetchSupportContacts();
    fetchGetInTouch();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "messages"), formData); // Save user input to Firestore
      alert("Your message has been sent!");
      setFormData({ name: "", email: "", message: "" }); // Reset the form
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Container className="contact-us-container">
      <h2 className="contact-us-title">{language === "ar" ? "اتصل بنا" : "Contact Us"}</h2>
      <div className="contact-us-layout">
        <div className="contact-form">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">{language === "ar" ? "الاسم" : "Name"}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder={language === "ar" ? "أدخل اسمك" : "Enter your name"}
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">{language === "ar" ? "البريد الإلكتروني" : "Email"}</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder={language === "ar" ? "أدخل بريدك الإلكتروني" : "Enter your email"}
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="message">{language === "ar" ? "الرسالة" : "Message"}</Label>
              <Input
                type="textarea"
                name="message"
                id="message"
                placeholder={language === "ar" ? "اكتب رسالتك هنا" : "Write your message here"}
                value={formData.message}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <Button type="submit" color="primary" className="submit-button">
              {language === "ar" ? "إرسال" : "Send"}
            </Button>
          </Form>
        </div>

        <div className="support-contacts">
          <h3>{language === "ar" ? "جهات الاتصال للدعم" : "Support Contacts"}</h3>
          <ul>
            {supportContacts.map((contact, index) => (
              <li key={index}>{`${contact.name}: ${contact.email}`}</li>
            ))}
          </ul>
          <h3>{language === "ar" ? "معلومات الاتصال" : "Get in Touch"}</h3>
          <ul>
            <li>{`${language === "ar" ? "العنوان:" : "Address:"} ${getInTouch.address}`}</li>
            <li>{`${language === "ar" ? "البريد الإلكتروني:" : "Email:"} ${getInTouch.email}`}</li>
            <li>{`${language === "ar" ? "الهاتف:" : "Phone:"} ${getInTouch.phone}`}</li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;