import React from "react";
import "./features.css";
import { Container, Row, Col } from "reactstrap";
import { useLanguage } from "../header/LanguageContext"; // Adjust the path accordingly

const FeatureData = [
  {
    title: {
      en: "Quick Learning",
      ar: "التعلم السريع",
    },
    desc: {
      en: "Our programs, including PYD English and IELTS Preparation, are designed for rapid skill acquisition. With structured lessons and practical exercises, you will see significant improvement in your English proficiency in a short time.",
      ar: "تم تصميم برامجنا، بما في ذلك الإنجليزية PYD وإعداد IELTS، لاكتساب المهارات بسرعة. مع الدروس المنظمة والتمارين العملية، سترى تحسنًا كبيرًا في مستوى إجادتك للغة الإنجليزية في وقت قصير.",
    },
    icon: "ri-draft-line",
  },
  {
    title: {
      en: "All Time Support",
      ar: "دعم دائم",
    },
    desc: {
      en: "We offer continuous support to our students through various channels. Whether you have questions about the STEP Preparation or need assistance with your coursework, our dedicated team is available to help you anytime.",
      ar: "نقدم دعمًا مستمرًا لطلابنا من خلال قنوات متعددة. سواء كان لديك أسئلة حول إعداد STEP أو كنت بحاجة إلى مساعدة في موادك الدراسية، فإن فريقنا المخصص متاح لمساعدتك في أي وقت.",
    },
    icon: "ri-discuss-line",
  },
];

const Features = () => {
  const { language } = useLanguage(); // Access the current language

  return (
    <section>
      <Container>
        <Row className="justify-content-center"> {/* Center the row */}
          {FeatureData.map((item, index) => (
            <Col lg="4" md="6" className="mb-4" key={index}> {/* Add margin for spacing */}
              <div className="single__feature text-center px-4">
                <h2 className="mb-3">
                  <i className={item.icon}></i>
                </h2>
                <h6>{item.title[language]}</h6>
                <p>{item.desc[language]}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;