// ManageCourses.jsx
import React, { useState } from 'react';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../FB/firebaseConfig'; // Adjust the path as necessary

const ManageCourses = ({ courseName }) => {
  const [videoFiles, setVideoFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [dragging, setDragging] = useState(false);

  const handleUpload = async (e) => {
    e.preventDefault();
    setLoadingMessage("Uploading... Please wait.");
    setSuccessMessage("");

    // Upload video files
    for (const videoFile of videoFiles) {
      const videoRef = ref(storage, `courses/${courseName}/videos/${videoFile.name}`);
      await uploadBytes(videoRef, videoFile);
    }

    // Upload document files
    for (const documentFile of documentFiles) {
      const docRef = ref(storage, `courses/${courseName}/documents/${documentFile.name}`);
      await uploadBytes(docRef, documentFile);
    }

    setSuccessMessage("Files uploaded successfully!");
    setVideoFiles([]); // Clear video files after upload
    setDocumentFiles([]); // Clear document files after upload
    setLoadingMessage(""); // Clear loading message after upload
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e, setFiles) => {
    e.preventDefault();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      setFiles(prevFiles => [...prevFiles, ...files]);
    }
  };

  return (
    <div className="manage-courses">
      <h3>Manage {courseName}</h3>

      <form onSubmit={handleUpload} className="upload-form">
        <div 
          className={`upload-area ${dragging ? 'dragging' : ''}`} 
          onDragOver={handleDragOver} 
          onDragLeave={handleDragLeave} 
          onDrop={(e) => handleDrop(e, setVideoFiles)}
        >
          {videoFiles.length > 0 ? (
            <ul>
              {videoFiles.map(file => <li key={file.name}>{file.name}</li>)}
            </ul>
          ) : (
            <p>Drag & drop your video files here, or click to select</p>
          )}
          <input 
            type="file" 
            accept="video/*" 
            onChange={(e) => setVideoFiles(Array.from(e.target.files))} 
            multiple // Allow multiple files
            style={{ display: 'none' }} 
          />
          <button 
            type="button" 
            className="btn-submit" 
            onClick={() => document.querySelector('input[type="file"]').click()}
          >
            Choose Files
          </button>
        </div>

        <button type="submit" className="btn-submit upload-button">Upload Videos</button>
      </form>

   

      {loadingMessage && <div className="loading">{loadingMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default ManageCourses;