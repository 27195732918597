import React from "react";
import ChooseUs from "../components/choose-us/ChooseUs";
import Features from "../components/features/Features";
import Footer from "../components/footer/Footer";
import HeroSection from "../components/hero-section/HeroSection";




const Home = () => {


  return (
    <>
      <HeroSection />
     
      <ChooseUs />
    
      <Features />

      
      <Footer />
    </>
  );
};

export default Home;
