import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Slider from "react-slick"; // تأكد من تثبيت react-slick وslick-carousel
import "./choose.css";

// استيراد صور الدورات
import englishBeginnersImg from "../../images/R.jpeg"; 
import businessEnglishImg from "../../images/branding.png"; 
import ieltsPrepImg from "../../images/ielts.png"; 
import { useLanguage } from "../header/LanguageContext"; 
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage"; // استيراد Firebase Storage

const ChooseUs = () => {
  const [modal, setModal] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [videos, setVideos] = useState([]); // New state for videos
  const { language } = useLanguage();

  const toggleModal = () => setModal(!modal);

  const handleCourseClick = (title) => {
    setCourseTitle(title);
    toggleModal();
  };

  const fetchVideos = async () => {
    const storage = getStorage();
    const videosRef = ref(storage, 'whyChooseUsVideos/'); // New directory for videos

    try {
      const response = await listAll(videosRef);
      const videoURLs = await Promise.all(
        response.items.map((item) => getDownloadURL(item))
      );
      setVideos(videoURLs);
    } catch (error) {
      console.error("Error fetching videos: ", error);
    }
  };

  useEffect(() => {
    fetchVideos(); // Fetch videos on component mount
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
  };

  const courses = [
    {
      title: {
        en: "IELTS Preparation",
        ar: "إعداد IELTS",
      },
      description: {
        en: "Comprehensive training to help you excel in the IELTS exam.",
        ar: "تدريب شامل لمساعدتك على التفوق في اختبار IELTS.",
      },
      location: "Online",
      image: ieltsPrepImg,
    },
    {
      title: {
        en: "STEP Preparation",
        ar: "إعداد STEP",
      },
      description: {
        en: "With STEP, you’ll find yourself interacting confidently. STEP determines your current level and helps you improve your English.",
        ar: "مع STEP، ستجد نفسك تتفاعل بثقة. يحدد STEP مستواك الحالي ويساعدك على تحسين لغتك الإنجليزية.",
      },
      location: "Online",
      image: businessEnglishImg,
    },
    {
      title: {
        en: "Discover Course",
        ar: "Discover كورس",
      },
      description: {
        en: "Aims to improve students' language skills in reading, writing, listening, and speaking.",
        ar: "يهدف إلى تحسين المهارات اللغوية للطلاب في القراءة والكتابة والاستماع والمحادثة",
      },
      location: "Online",
      image: englishBeginnersImg,
    },
    {
      title: {
        en: "Headway Course",
        ar: "Headway  كورس",
      },
      description: {
        en: "Aims to enhance learners' English language skills across reading, writing, listening, and speaking.",
        ar: "يهدف إلى تحسين المهارات اللغوية للطلاب في القراءة والكتابة والاستماع والمحادثة",
      },
      location: "Online",
      image: englishBeginnersImg,
    },
  ];

  return (
    <section>
      <Container>
        <Row>
          <Col lg="6">
            <div className="choose__content">
              <h2>{language === "ar" ? "لماذا نحن" : "Why Choose Us"}</h2>
              <Slider {...settings}>
                {videos.map((videoURL, index) => (
                  <div key={index}>
                    <video controls style={{ width: '100%', height: 'auto' }}>
                      <source src={videoURL} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
          <Col lg="6">
            <div className="choose__courses-card">
              <h2 className="courses-title">{language === "ar" ? "دوراتنا الشائعة" : "Our Popular Courses"}</h2>
              <Slider {...settings}>
                {courses.map((course, index) => (
                  <div key={index} className="single__course" onClick={() => handleCourseClick(course.title[language])}>
                    <img src={course.image} alt={course.title[language]} className="course-image" />
                    <h6 className="mb-3 fw-bold">{course.title[language]}</h6>
                    <p>{course.description[language]}</p>
                    <div className="course__info mt-4">
                      <p>Location: {course.location}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal for Sign In Prompt */}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>{language === "ar" ? "يتطلب تسجيل الدخول" : "Sign In Required"}</ModalHeader>
        <ModalBody>
          {language === "ar" 
            ? `يرجى إنشاء حساب أولاً لعرض تفاصيل دورة ${courseTitle}.`
            : `Please create account first to view details for ${courseTitle}.`}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
          {/* يمكنك إضافة رابط إلى صفحة تسجيل الدخول هنا */}
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default ChooseUs;