import React, { useRef } from "react";
import { Container } from "reactstrap";
import { useLanguage } from "./LanguageContext";

import "./header.css";
import logo from '../../images/WhatsApp_Image_2024-09-19_at_20.08.01_2e4e8613-removebg-preview.png'; 

const navLinks = [

  { display: { en: "Home", ar: "الرئيسية" }, url: "/Home" },
  { display: { en: "About us", ar: "عننا" }, url: "/About" },
  { display: { en: "Contact Us", ar: "اتصل بنا" }, url: "/contact" } ,
  { display: { en: "Employee", ar: "اداري" },       url: "/loginemployee" },

];

const Header = () => {
  const { toggleLanguage, language } = useLanguage();
  const menuRef = useRef();
  const menuToggle = () => menuRef.current.classList.toggle("active__menu");


  return (
    <header className="header">
      <Container>
        <div className="navigation d-flex align-items-center justify-content-between">
          <div className="logo d-flex align-items-center">
            <img src={logo} alt="Logo" className="logo-image" />
            <h2>{language === "ar" ? "اكاديمية التفوق" : "Altafawoq"}</h2>
          </div>
          <div className="nav d-flex align-items-center gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list">
                {navLinks.map((item, i) => (
                  <li key={i} className="nav__item">
                    <a href={item.url}>
                      {item.display[language]}
                    </a>
                  </li>
                ))}
                <li className="nav__item">
                  <button className="btn-language" onClick={toggleLanguage}>
                    {language === "ar" ? "AR" : "EN"}
                  </button>
                </li>
              </ul>
            </div>
            
            <div className="mobile__menu">
              <span>
                <i className="ri-menu-line" onClick={menuToggle}></i>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;