import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCnf06TJSJSRFIALFCMVvWwIDaHiqA_CeM",
  authDomain: "edu-sy.firebaseapp.com",
  projectId: "edu-sy",
  storageBucket: "edu-sy.appspot.com",
  messagingSenderId: "468273785220",
  appId: "1:468273785220:web:a9c0e19a5bfc41b74f15d1"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize Auth
const storage = getStorage(app);

export { db, auth, storage };