import React from "react";
import { useLanguage } from "./LanguageContext"; // Import the context
import { Container, Row, Col } from "reactstrap";
import aboutImg from "../../images/WhatsApp_Image_2024-09-19_at_20.08.01_2e4e8613-removebg-preview.png";
import "./css.css";

const translations = {
  en: {
    title: "About us",
    description: `Altafawoq Academy is a specialized educational institution for teaching English language to all 
    levels and ages. We introduce courses for anyone who wants to apply for STEP, IELTS, and TOEFL. 
    Also, we introduce courses to those who want to learn English or develop their skills. 
    The Academy works under the supervision of the Ministry of Education and is authorized by the 
    Saudi Ministry of Education. Our curriculum is certified and authorized by the 
    Ministry of Education.`,
  },                       
  ar: {
    title: "من نحن",
    description: `أكاديمية التفوق هي مؤسسة تعليمية متخصصة في تدريس اللغة الإنجليزية لجميع 
    المستويات والأعمار. نقدم دورات لأي شخص يرغب في التقدم لامتحانات STEP وIELTS وTOEFL.           
    كما نقدم دورات لمن يرغب في تعلم اللغة الإنجليزية أو تطوير مهاراته.                    
    تعمل الأكاديمية تحت إشراف وزارة التعليم وهي مرخصة من قبل وزارة التعليم السعودية.     
    منهجنا معتمد ومصرح به من وزارة التعليم.`,                                             
  
  },
};

const About = () => {                
  const { language } = useLanguage();
                                     
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__img">                     
              <img src={aboutImg} alt="" className="w-100" />
            </div>                                            
          </Col>
          <Col lg="6" md="6">
            <div className="about__content">
              <h2>{translations[language].title}</h2>    
              <p>{translations[language].description}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;








