import React, { useState } from 'react';
import { storage } from '../FB/firebase'; // Adjust the path based on your structure
import { ref, uploadBytes } from 'firebase/storage';
import { getFirestore, collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import styles from './AddCourse.module.css'; // Import the CSS module

const db = getFirestore(); // Initialize Firestore

const AddCourse = () => {
    const [courseName, setCourseName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [tutorName, setTutorName] = useState('');
    const [coursePhoto, setCoursePhoto] = useState(null);
    const [status, setStatus] = useState('');

    const handlePhotoChange = (e) => {
        setCoursePhoto(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!courseName || !price || !description || !tutorName || !coursePhoto) {
            setStatus('Please fill in all fields!');
            return;
        }

        try {
            const photoRef = ref(storage, `courses/${courseName}/photos/${coursePhoto.name}`);

            await uploadBytes(photoRef, coursePhoto);

            // Save course details to Firestore
            await addDoc(collection(db, 'courses'), {
                name: courseName,
                price: parseFloat(price),
                description: description,
                tutorName: tutorName,
                photoUrl: photoRef.fullPath,
            });

            setStatus('Course added successfully!');
            // Reset form
            setCourseName('');
            setPrice('');
            setDescription('');
            setTutorName('');
            setCoursePhoto(null);
        } catch (error) {
            setStatus('Error: ' + error.message);
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Add New Course</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.formGroup}>
                    <label>Course Name:</label>
                    <input
                        type="text"
                        value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Price:</label>
                    <input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Description:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        className={styles.textarea}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Tutor Name:</label>
                    <input
                        type="text"
                        value={tutorName}
                        onChange={(e) => setTutorName(e.target.value)}
                        required
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Course Photo:</label>
                    <input 
                        type="file" 
                        onChange={handlePhotoChange} 
                        required 
                        className={styles.fileInput}
                    />
                </div>
                <button type="submit" className={styles.submitButton}>Add Course</button>
            </form>
            <div className={status.includes('Error') ? styles.error : styles.status}>{status}</div>
        </div>
    );
};

export default AddCourse;