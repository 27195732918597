import React, { useState } from "react";
import { db } from "../FB/firebaseConfig.js";
import { collection, addDoc } from "firebase/firestore";

const ManageExams = ({ courses }) => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [examTitle, setExamTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: "", options: ["", "", "", ""], correctOption: "" }]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleCorrectOptionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].correctOption = value;
    setQuestions(newQuestions);
  };

  const handleAddExam = async (e) => {
    e.preventDefault();
    setLoading(true);
    const examsRef = collection(db, "exams");

    try {
      // Create the exam document with title and course name
      const examDoc = await addDoc(examsRef, { 
        title: examTitle, 
        courseName: selectedCourse 
      });
      const examId = examDoc.id;

      // Handle questions
      for (const question of questions) {
        await addDoc(collection(db, "questions"), {
          examId,
          question: question.question,
          options: question.options,
          correctOption: question.correctOption,
          courseName: selectedCourse,
          title: examTitle // Include the exam title with each question
        });
      }

      setSuccessMessage("Exam and questions added successfully!");
      setExamTitle("");
      setQuestions([]);
    } catch (error) {
      console.error("Error adding exam:", error);
      setSuccessMessage("Error adding exam. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="courseSelect">Select Course</label>
        <select
          id="courseSelect"
          value={selectedCourse}
          onChange={(e) => setSelectedCourse(e.target.value)}
          required
          className="styled-select"
        >
          <option value="">Select Course</option>
          {courses.map(course => (
            <option key={course.name} value={course.name}>{course.name}</option>
          ))}
        </select>
      </div>

      <form onSubmit={handleAddExam} className="manage-course-form">
        <div className="form-group">
          <label htmlFor="examTitle">Exam Title</label>
          <input
            type="text"
            id="examTitle"
            value={examTitle}
            onChange={(e) => setExamTitle(e.target.value)}
            required
            className="styled-input"
          />
        </div>

        {questions.map((q, index) => (
          <div key={index} className="question-block">
            <div className="form-group">
              <label htmlFor={`question_${index}`}>Question</label>
              <input
                type="text"
                id={`question_${index}`}
                value={q.question}
                onChange={(e) => handleQuestionChange(index, e.target.value)}
                required
                className="styled-input"
              />
            </div>

            {q.options.map((option, optionIndex) => (
              <div key={optionIndex} className="form-group">
                <label htmlFor={`option_${index}_${optionIndex}`}>Option {optionIndex + 1}</label>
                <input
                  type="text"
                  id={`option_${index}_${optionIndex}`}
                  value={option}
                  onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                  required
                  className="styled-input"
                />
              </div>
            ))}

            <div className="form-group">
              <label htmlFor={`correctOption_${index}`}>Correct Option (1-{q.options.length})</label>
              <input
                type="number"
                id={`correctOption_${index}`}
                value={q.correctOption}
                onChange={(e) => handleCorrectOptionChange(index, e.target.value)}
                required
                min="1"
                max={q.options.length}
                className="styled-input"
              />
            </div>
          </div>
        ))}

        <button type="button" className="btn-submit" onClick={handleAddQuestion}>
          Add Question
        </button>

        <button type="submit" className="btn-submit" disabled={loading}>
          {loading ? "Adding..." : "Add Exam and Questions"}
        </button>
      </form>

      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default ManageExams;