import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from './firebaseConfig'; // Adjust the path as needed
import { collection, getDocs } from 'firebase/firestore';
import styles from './Payment.module.css';

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const course = location.state?.course; // Get course details passed from UserProfilePage

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isArabic, setIsArabic] = useState(false); // Default to English

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      const paymentCollection = collection(db, 'paymentDetails');
      const paymentSnapshot = await getDocs(paymentCollection);
      const details = paymentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Assuming you have only one document for payment details
      if (details.length > 0) {
        setPaymentDetails(details[0]); // Set the first document's data
      }
    };

    fetchPaymentDetails();
  }, []);

  const handlePaymentConfirmation = () => {
    alert("Payment confirmed. Course will be added.");
    navigate("/profile");
  };

  const toggleLanguage = () => {
    setIsArabic(prev => !prev); // Toggle language state
  };

  if (!paymentDetails) {
    return <p>Loading payment details...</p>;
  }

  const notes = isArabic ? paymentDetails.arabicNotes : paymentDetails.notes;

  return (
    <div className={styles.paymentPage} dir={isArabic ? "rtl" : "ltr"}>
      <h2>{isArabic ? "تفاصيل الحساب البنكي" : "Bank Account Details"}</h2>
      <button onClick={toggleLanguage} className={styles.languageButton}>
        {isArabic ? "Switch to English" : "تبديل إلى العربية"}
      </button>
      <div className={styles.detailsContainer}>
        <div className={styles.leftColumn}>
          <h3>{isArabic ? "تفاصيل الحساب" : "Account Details"}</h3>
          <p><strong>{isArabic ? "اسم الحساب:" : "Account Name:"}</strong> {paymentDetails.accountName}</p>
          <p><strong>{isArabic ? "رقم الحساب:" : "Account Number:"}</strong> {paymentDetails.accountNumber}</p>
          <p><strong>{isArabic ? "IBAN:" : "IBAN:"}</strong> {paymentDetails.iban}</p>
        </div>
        <div className={styles.middleColumn}>
          <h3>{isArabic ? "معلومات الاتصال" : "Contact Information"}</h3>
          <p><strong>{isArabic ? "واتساب:" : "WhatsApp:"}</strong> {paymentDetails.whatsApp}</p>
          <p><strong>{isArabic ? "البريد الإلكتروني:" : "Email:"}</strong> {paymentDetails.email}</p>
        </div>
        <div className={styles.rightColumn}>
          <h3>{isArabic ? "تفاصيل الشركة" : "Company Details"}</h3>
          <p><strong>{isArabic ? "اسم الشركة:" : "Company Name:"}</strong> {paymentDetails.companyName}</p>
          <p><strong>{isArabic ? "رقم التسجيل:" : "Registration Number:"}</strong> {paymentDetails.registrationNumber}</p>
          <p><strong>{isArabic ? "عنوان الشركة:" : "Company Address:"}</strong> {paymentDetails.companyAddress}</p>
        </div>
      </div>
      <div className={styles.notesContainer}>
        <h3>{isArabic ? "ملاحظات !!" : "Notes!!"}</h3>
        <p>{notes}</p>
      </div>
      <button onClick={handlePaymentConfirmation} className={styles.confirmButton}>
        {isArabic ? "تأكيد الدفع" : "Confirm Payment"}
      </button>
    </div>
  );
};

export default PaymentPage;