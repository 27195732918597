import React, { useEffect, useState } from "react";
import { storage } from "../FB/firebaseConfig.js"; // Adjust the import based on your file structure
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';

const ManageWhyChooseUsVideos = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [videoList, setVideoList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchVideos = async () => {
    const videoRef = ref(storage, 'whyChooseUsVideos/');
    const videoListResponse = await listAll(videoRef);
    const videoUrls = await Promise.all(
      videoListResponse.items.map(video => getDownloadURL(video))
    );
    setVideoList(videoUrls);
    setLoading(false);
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!videoFile) return;

    const videoRef = ref(storage, `whyChooseUsVideos/${videoFile.name}`);
    await uploadBytes(videoRef, videoFile);
    setVideoFile(null);
    // Refresh the video list after upload
    fetchVideos();
  };

  const handleDelete = async (videoUrl) => {
    const videoRef = ref(storage, videoUrl);
    await deleteObject(videoRef);
    // Refresh the video list after deletion
    fetchVideos();
  };

  if (loading) {
    return <div className="loading">Loading videos...</div>;
  }

  return (
    <div>
      <h2>Manage Why Choose Us Videos</h2>
      <form onSubmit={handleUpload}>
        <input 
          type="file" 
          onChange={(e) => setVideoFile(e.target.files[0])} 
          required 
        />
        <button type="submit">رفع اعلان</button>
      </form>

      <h3></h3>
      <ul>
        {videoList.map((url, index) => (
          <li key={index}>
            <video width="320" height="240" controls>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button onClick={() => handleDelete(url)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageWhyChooseUsVideos;