import React, { useEffect, useState } from 'react';
import { db, storage } from "../FB/firebaseConfig";
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { ref, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from './CourseVideos.module.css'; // Import your CSS styles

const ItemType = {
  VIDEO: 'video',
};

const DraggableVideoItem = ({ video, index, moveVideo, handleDeleteVideo, handleVideoClick }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.VIDEO,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <li
      ref={drag}
      className={`${styles.videoItem} ${isDragging ? styles.dragging : ''}`}
    >
      <span className={styles.videoName} onClick={() => handleVideoClick(video)}>
        {video.name}
      </span>
      <button
        className={styles.deleteButton}
        onClick={() => handleDeleteVideo(video)}
      >
        Delete
      </button>
    </li>
  );
};

const CourseVideos = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesRef = collection(db, "courses");
        const courseSnapshot = await getDocs(coursesRef);
        const courseData = courseSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCourses(courseData);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchVideosFromStorage = async () => {
      if (selectedCourse) {
        try {
          const videosRef = ref(storage, `courses/${selectedCourse.name}/videos`);
          const videoList = await listAll(videosRef);
          const videoData = await Promise.all(
            videoList.items.map(async (video) => {
              const url = await getDownloadURL(video);
              return { name: video.name, url, path: video.fullPath };
            })
          );
          setVideos(videoData);
        } catch (error) {
          console.error("Error fetching videos:", error);
        }
      }
    };

    fetchVideosFromStorage();
  }, [selectedCourse]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleDeleteVideo = async (video) => {
    // Existing delete logic...
  };

  const moveVideo = (fromIndex, toIndex) => {
    const updatedVideos = [...videos];
    const [movedVideo] = updatedVideos.splice(fromIndex, 1);
    updatedVideos.splice(toIndex, 0, movedVideo);
    setVideos(updatedVideos);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <h2>Select Course to View Videos</h2>
        <select
          onChange={(e) => {
            const course = courses.find(course => course.id === e.target.value);
            setSelectedCourse(course);
            setVideos([]);
            setSelectedVideo(null);
          }}
          value={selectedCourse ? selectedCourse.id : ""}
        >
          <option value="">Select Course</option>
          {courses.map(course => (
            <option key={course.id} value={course.id}>{course.name}</option>
          ))}
        </select>

        {selectedCourse && (
          <div>
            <h3>Videos in {selectedCourse.name}</h3>
            <div className={styles.videoContainer}>
              {videos.length > 0 ? (
                <>
                  <div className={styles.mainVideoWrapper}>
                    {selectedVideo ? (
                      <video
                        key={selectedVideo.url}
                        controls
                        className={styles.mainVideo}
                      >
                        <source src={selectedVideo.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p>Select a video to play</p>
                    )}
                  </div>

                  <ul className={styles.videoList}>
                    {videos.map((video, index) => (
                      <DraggableVideoItem
                        key={index}
                        index={index}
                        video={video}
                        moveVideo={moveVideo}
                        handleDeleteVideo={handleDeleteVideo}
                        handleVideoClick={handleVideoClick} // Pass the function as a prop
                      />
                    ))}
                  </ul>
                </>
              ) : (
                <p>No videos available for this course.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default CourseVideos;