import React, { useEffect, useState } from 'react';
import { db } from '../FB/firebaseConfig'; // Adjust if needed
import { collection, getDocs, addDoc } from 'firebase/firestore';

const EnrolledUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [courses, setCourses] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [enrollmentMessage, setEnrollmentMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userCollection = collection(db, 'users');
        const userSnapshot = await getDocs(userCollection);
        const userData = userSnapshot.docs.map(doc => ({
          id: doc.id,
          uid: doc.data().uid,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          phone: doc.data().phone,
          email: doc.data().email,
        }));
        setUsers(userData);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Fetch courses when a user is selected
  const fetchCourses = async (userId) => {
    setLoadingCourses(true);
    try {
      const courseCollection = collection(db, 'courses');
      const courseSnapshot = await getDocs(courseCollection);
      const courseData = courseSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        description: doc.data().description,
      }));
      setCourses(courseData);
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
      setLoadingCourses(false);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    fetchCourses(user.uid); // Fetch courses for the selected user
  };

  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
  };

  const enrollUserInCourse = async () => {
    if (!selectedUser || !selectedCourse) {
      return;
    }

    try {
      const enrollmentsRef = collection(db, 'enrollments');
      await addDoc(enrollmentsRef, {
        userId: selectedUser.uid,
        courseId: selectedCourse.id,
        courseName: selectedCourse.name,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        phone: selectedUser.phone,
        timestamp: new Date(),
      });

      setEnrollmentMessage(`Successfully enrolled ${selectedUser.firstName} ${selectedUser.lastName} in ${selectedCourse.name}`);
      setSelectedCourse(null); // Clear selected course after enrollment
    } catch (error) {
      console.error('Error enrolling user in course:', error);
    }
  };

  const filteredUsers = users.filter(user => 
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phone.includes(searchTerm)
  );

  if (loading) {
    return <div className="loading">Loading users...</div>;
  }

  return (
    <div>
      <h2>All Users</h2>
      <input
        type="text"
        placeholder="Search by email or phone number"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="styled-input"
      />
      {filteredUsers.length > 0 ? (
        <table className="enrolled-users-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>User ID</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map(user => (
              <tr key={user.id}>
                <td>
                  <button onClick={() => handleUserSelect(user)}>Select</button>
                </td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.uid}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No users available.</p>
      )}

      {selectedUser && (
        <div>
          <h3>Courses for {selectedUser.firstName} {selectedUser.lastName}</h3>
          {loadingCourses ? (
            <div className="loading">Loading courses...</div>
          ) : (
            courses.length > 0 ? (
              <ul>
                {courses.map(course => (
                  <li key={course.id}>
                    <strong>{course.name}</strong>: {course.description}
                    <button onClick={() => handleCourseSelect(course)}>Select Course</button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No courses available.</p>
            )
          )}
        </div>
      )}

      {selectedCourse && (
        <div>
          <h4>Selected Course: {selectedCourse.name}</h4>
          <button onClick={enrollUserInCourse}>Enroll User in Course</button>
        </div>
      )}

      {enrollmentMessage && <div className="success-message">{enrollmentMessage}</div>}
    </div>
  );
};

export default EnrolledUsers;