// src/App.js
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LanguageProvider } from "./components/header/LanguageContext"; // Import the LanguageProvider
import Home from "./pages/Home";
import About from "./components/header/About";
import Layout from './Layout';
import DashboardPage from './ADMIN/DashboardPage.jsx';
import UserProfilePage from "./user/UserProfilePage.jsx"; // User profile page
import CourseDetailPage from './user/CourseDetailPage'; 
import LoginEmployee from "./ADMIN/LoginEmployee.jsx";
import ContactUs from './components/header/ContactUs'; // Import the ContactUs component
import PaymentPage from './user/PaymentPage';
function App() {
  return (
    <LanguageProvider> 
      <Router>         
        <div className="App">
          <Layout>
            <Routes>
              <Route path="/Home" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/About" element={<About />} />
              <Route path="/dashboard054405910yusuf2404747" element={<DashboardPage />} />
              <Route path="/profile" element={<UserProfilePage />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/course/:courseId" element={<CourseDetailPage />} />
              <Route path="/loginemployee" element={<LoginEmployee />} />
              <Route path="/contact" element={<ContactUs />} /> 
            </Routes>
          </Layout>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;