import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { storage } from "./firebase"; // Ensure the correct path
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { db } from "./firebaseConfig"; // Import your Firestore configuration
import { doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import styles from './CourseDetail.module.css'; // CSS for course details

const CourseDetailPage = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [videos, setVideos] = useState([]);
  const [videoNames, setVideoNames] = useState([]); // State to hold video names
  const [documents, setDocuments] = useState([]); // Fixed typo here
  const [loading, setLoading] = useState(true);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0); // Track index of selected video
  const [videoEnded, setVideoEnded] = useState(false); // Track if the video has ended
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate("/"); // Redirect to home if not authenticated
      } else {
        await fetchCourseData(courseId);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, navigate, courseId]); 

  const fetchCourseData = async (courseId) => {
    const courseRef = doc(db, "courses", courseId);
    const courseSnap = await getDoc(courseRef);
    if (courseSnap.exists()) {
      const courseData = courseSnap.data();
      setCourse(courseData);
      await fetchCourseResources(courseData.name);
    } else {
      console.log("No such course!");
      setLoading(false);
    }
  };

  const fetchCourseResources = async (courseName) => {
    try {
      const videoRef = ref(storage, `courses/${courseName}/videos`);
      const documentRef = ref(storage, `courses/${courseName}/documents`);

      const videoList = await listAll(videoRef);
      const videoUrls = await Promise.all(
        videoList.items.map((video) => getDownloadURL(video))
      );
      const videoNames = videoList.items.map((video) => video.name); // Get video names
      setVideos(videoUrls);
      setVideoNames(videoNames); // Set video names

      const documentList = await listAll(documentRef);
      const documentUrls = await Promise.all(
        documentList.items.map((doc) => getDownloadURL(doc))
      );
      setDocuments(documentUrls);
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  useEffect(() => {
    if (videos.length > 0) {
      setSelectedVideoIndex(0); // Default to the first video index
    }
  }, [videos]);

  const handleVideoClick = (index) => {
    setSelectedVideoIndex(index); // Update index of selected video
    setVideoEnded(false); // Reset video end state when a new video is selected
  };

  const handleVideoEnded = () => {
    setVideoEnded(true); // Set video ended state
  };

  const handleNextVideo = () => {
    const nextIndex = (selectedVideoIndex + 1) % videos.length; // Loop back to the first video
    setSelectedVideoIndex(nextIndex); // Update selected video index
    setVideoEnded(false); // Reset video end state for the next video
  };

  const preventContextMenu = (e) => {
    e.preventDefault(); // Prevent right-click context menu
  };

  if (loading) {
    return <p>Loading course details...</p>;
  }

  return (
    <div className={styles.courseDetail} onContextMenu={preventContextMenu}>
      <aside className={styles.sidebar}>
        <h2>{course ? course.name : "Course Not Found"}</h2>
        <button onClick={() => navigate("/profile")} className={ styles.navButton}>Back to Profile</button>
        <button onClick={async () => { await auth.signOut(); navigate("/"); }} className={styles.navButton}>Logout</button>

        <h3>Other Videos:</h3>
        <div className={styles.videoList}>
          {videos.map((videoUrl, index) => (
            <div key={index} className={styles.videoWrapper}>
              <video 
                className={styles.sidebarVideo} 
                onClick={() => handleVideoClick(index)} // Pass index to update main video
                muted 
                controls={false} 
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p>{videoNames[index]}</p> {/* Display video name */}
            </div>
          ))}
        </div>
      </aside>

      <main className={styles.mainContent}>
        {course ? (
          <>
            <h3>Video:</h3>
            {videos.length > 0 && (
              <div className={styles.videoContainer}>
                <div className={styles.videoWrapper}>
                  <video 
                    className={styles.mainVideo} 
                    controls 
                    onEnded={handleVideoEnded} // Set video ended state
                    key={selectedVideoIndex} // Key prop to force re-render
                    onContextMenu={preventContextMenu} // Prevent right-click on video
                  >
                    <source src={videos[selectedVideoIndex]} type="video/mp4" />
                    Your browser does not support the video tag please contact support.
                  </video>
                  <div className={styles.watermark}>Altafawoq</div>
                </div>
                {videoEnded && ( // Show Next button when video ends
                  <button onClick={handleNextVideo} className={styles.nextButton}>
                    Next
                  </button>
                )}
              </div>
            )}
          </>
        ) : (
          <p>Course details not found.</p>
        )}
      </main>
    </div>
  );
};

export default CourseDetailPage;